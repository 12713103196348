import React, { useState, useEffect } from 'react'
import { StyledButton, Image, ImageLine } from './Preset'
import StyledContainer from './Styled'
import headerBg from '../assets/images/idoHeader.svg'
import useMobile from '../hooks/useMobile'
import arrow from '../assets/images/arrow.svg'
import whiteList from '../assets/whitelist'
import styled from 'styled-components'
import Overlay from './Overlay'
import useRequest from '@ahooksjs/use-request'
import { useWeb3React } from '@web3-react/core'
import useAuth from '../hooks/useAuth'
import { useTokenContract } from '../hooks/useContract'
import Notice from './notice'
import { useTimer } from 'react-timer-hook';
import mail from '../assets/images/fi_mail.svg'
import CheckTransactionStatus from './CheckTransactionStatus'
import Loading from './Loading'
import axios from 'axios'




const Title = styled(StyledContainer)`
    position: relative;
    ::after{
        content: '';
        display: inline-block;
        position: absolute;
        // top: -30px;
        top: -.8em;
        left: -20px;
        // width: 54.43px;
        // height: 10.89px;
        width: 1.2em;
        height: .3em;

        background: linear-gradient(83.41deg, #DE13FF -91.12%, #322EFB 222.17%);
        transform: rotate(-15deg);
    }
`

const Input = styled.input`
    background: rgba(77, 60, 85, 0.12);
    border: 1px solid #8D80D5;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 8px 16px;
    color: #fff;
`

const Button = styled.button`
    background: #6425C5;
    box-sizing: border-box;
    border-radius: 8px;
    width: 206px;
    height: 52px;
    font-size: 20px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: none;
    cursor: pointer;
`
const Text = styled.div`
    font-family: GTText;
    font-style: normal;
    font-weight: normal;
    font-size: 0.8em;
    // line-height: 15px;
    display: flex;
    align-items: center;
    letter-spacing: 0.5px;
    color:#fff;
    margin-top:10px
`
const Star = styled.div`
    position:relative;
    &::after {
        content: '';
        background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAExSURBVHgBrZRBTsMwEEX/2G0lVvWyuzhcoOVmbFmxYccBWHIEjkL3SCU5Qb0gTZPaHmxxgH6JfmnkSHn5sf9MApD6dOoYzjDQwem2uHmGpQyzRZssZ7hgIKtoMiAMSxleBG2kzkIangy2URAYljIcDFwEGoYld6h+phIkuvyxUT+IrMdS75vRX+OlDuy8QjMB7WSzzzBNgvoosoui7gKsS1OkNqVc1waFsnZ1naH7aFIf8/J7XF7616/V3jwECVEhCcUGRutD55LZaBBKdjhZyGAUA6CDQE+iKOUm5DCX+xNsqb+XPXp1V5N58+rP0EPdZdKpferuOvxXL206Pt+nI8OyYxNKprebwx/RfRbub8MZWu0T0DMsO9hdAicuw4XpUgQl7sgpdyA/PUq7HdeQql94Z4eNSGBvzwAAAABJRU5ErkJggg==') no-repeat;
        position: absolute;
        top: 20px;
        left:-20px;
        z-index: 100;
        width: 32px;
        height: 16px;
        display:block;
    };
    &::before {
        content: '';
        background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAExSURBVHgBrZRBTsMwEEX/2G0lVvWyuzhcoOVmbFmxYccBWHIEjkL3SCU5Qb0gTZPaHmxxgH6JfmnkSHn5sf9MApD6dOoYzjDQwem2uHmGpQyzRZssZ7hgIKtoMiAMSxleBG2kzkIangy2URAYljIcDFwEGoYld6h+phIkuvyxUT+IrMdS75vRX+OlDuy8QjMB7WSzzzBNgvoosoui7gKsS1OkNqVc1waFsnZ1naH7aFIf8/J7XF7616/V3jwECVEhCcUGRutD55LZaBBKdjhZyGAUA6CDQE+iKOUm5DCX+xNsqb+XPXp1V5N58+rP0EPdZdKpferuOvxXL206Pt+nI8OyYxNKprebwx/RfRbub8MZWu0T0DMsO9hdAicuw4XpUgQl7sgpdyA/PUq7HdeQql94Z4eNSGBvzwAAAABJRU5ErkJggg==') no-repeat;
        position: absolute;
        bottom: 0px;
        left:81%;
        z-index: 100;
        width: 32px;
        height: 16px;
        display:block;
    }

`
const Divider = styled(StyledContainer)`
    background: rgba(255, 255, 255, 0.12);
    margin: ${({ mobileSize }) => (mobileSize ? '36px' : '16px')} 0;
    width: 100%;
    height: 1px;
`


const contractAddress = process.env.REACT_APP_USDT_E_CONTRACT_ADDRESS
const recipientAddress = process.env.REACT_APP_RECIPIENT


export default ({ ifMobile }) => {
    const [ifShow, setShowModal] = useState(false)
    // const [address, setAddress] = useState(null)
    const [checked, setChecked] = useState(false)
    const [finish, setFinish] = useState(false)
    const [depositStatus, setDepositStatus] = useState(false)
    const [ifLoading, setIfLoading] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')

    const tokenContract = useTokenContract(contractAddress)

    const { account } = useWeb3React()
    const { login } = useAuth()

    const { run, data, loading: checkStatusLoading } = useRequest(async ({ account }) => new Promise(async (res) => (
        setTimeout(async () => {
            let _ = false;
            try{
                const paidStatus = await axios.get(`https://test.hurricaneswap.com/ido/stat?addr=${account.toLowerCase()}`)
                if(paidStatus && paidStatus?.data === 'Error'){
                    _ = false
                }else{
                    _ = true
                    setDepositStatus(true)
                }
            }catch(e){
                _ = false
                console.log('error', e)
            }
            // ...
            const Amount = whiteList[account.toLowerCase()] ? whiteList[account.toLowerCase()] : 0
            // paid 占位 true，取paidStatus的结果
            res({ u: Amount, paid: _ })
        }, 1000)
    )), { manual: true })


    useEffect(() => {
        if (account) {
            setChecked(false)
            closeModal()
            setDepositStatus(false)
            setIfLoading(false)
            setErrorMsg('')
            run({ account })
        }
    }, [account])

    useEffect(() => {
      //  console.log('data', data)
        if (data) {
            setChecked(true)
        }
    }, [data])


    var time = new Date(Date.UTC(2021, 8, 27, 14, 0, 0))
    //var time = new Date('2021-09-27:22:00:00')
    time = time.getTime()

    const {
        hours,
        seconds,
        minutes,
        pause,
        restart,
    } = useTimer({
        expiryTimestamp: time,
        onExpire: () => setFinish(true)
    });
    useEffect(
        () => {
            //restart(time);
        },
        [],
    );

    const closeModal = () => {
        setShowModal(false)
    }
    const showModal = () => {
        setShowModal(true)
    }

    const connectWallet = async () => {
        if (!account) {
            login()
            return;
        }

        if (!checked) {
            return;
        }

        if (depositStatus) {
            return;
        }

        if (ifLoading) {
            return;
        }
        // 100
        if (!data || !data?.u || data?.paid) {
            return;
        }

        const value = data?.u * Math.pow(10, 6)
        // const value = Math.random() * 1000 * Math.pow(10, 6)

        try {
            setIfLoading(true)
            const res = await tokenContract.transfer(recipientAddress, value.toFixed().toString())
            const t = await res.wait()
            // console.log('t', t, t?.transactionHash, t?.status)
            setDepositStatus(true)
            setErrorMsg('')
            setIfLoading(false)
            if (ifShow) {
                closeModal()
            }
            showModal(true)
        } catch (e) {
            setErrorMsg('')
            // code 4001 denied
            console.log('err', e)
            if(e?.data?.code === 3){
                // console.log('err', e?.data?.message)      
                setErrorMsg(e?.data?.message)      
            }
            setIfLoading(false)
            setDepositStatus(false)
            if (ifShow) {
                closeModal()
            }
            showModal(true)
        }
    }

    useEffect(() => {
        if (data) {
            setChecked(true)
        }
    }, [data])
    return (
        <>
            <StyledContainer>
                <StyledContainer mb={ifMobile ? 'auto' : '30px'} pt={ifMobile ? '105px' : '32px'} ml={ifMobile ? '-8px' : '0'} ml={ifMobile ? '-8px' : '0'} pl={ifMobile ? '0' : '13px'} relative flex column maxWidth='889px' width='100%'>
                    <StyledContainer>
                        <StyledContainer maxWidth={ifMobile ? 'calc(100vw - 20px)' : '899px'}>
                            <Image src={headerBg} />
                            {/* <StyledContainer textAlign='left' top={ifMobile ? 'calc(105px + 11em)' : 'calc(32px + 11em)'} left={ifMobile ? '5em' : '11em'} position='absolute' maxWidth='889px' flex column> */}

                            <StyledContainer textAlign='left' top={ifMobile ? 'calc(105px + 11em)' : 'calc(32px + 11em)'} left={ifMobile ? '9%' : '11em'} position='absolute' maxWidth='889px' flex column>
                                <Title fontWeight='bold' fontSize='3em' color='#fff'>HurricaneSwap Token </Title>
                                <StyledContainer mt={ifMobile ? '22px' : 0} fontWeight='bold' fontSize='4.5em' color='#fff'>IDO</StyledContainer>
                                {
                                    !finish ? (
                                        <>
                                        
                                        <StyledContainer fontWeight='bold' fontSize='3.5em' color='#fff'>
                                            <span style={{fontSize:'30px',color:'fff',marginRight:'15px'}}>End in</span>
                                            <div style={{width:'220px',display:'inline-block',letterSpacing:'0.5px'}}>{hours<10?'0'+hours :hours}:{minutes<10? '0'+minutes :minutes}:{seconds<10? '0'+seconds :seconds}</div>
                                        </StyledContainer>
                                        <StyledContainer mt='14px' fontWeight='bold' fontSize={ifMobile ? '32px' : '37px'} color='rgba(255, 255, 255, 0.65)' display='inline-block'>-Whitelisted users only-</StyledContainer>
                                        </>
                                    ) : (
                                        <>
                                            <StyledContainer mt={ifMobile ? '22px' : '0px'} fontWeight='bold' fontSize='4.5em' color='#fff'>Closed</StyledContainer>
                                            <Star>
                                                <StyledContainer mt={ifMobile ? '22px' : '20px'} fontWeight='normal' fontSize='1.2em' color='#fff'>You will receive HCT on Oct 10th.</StyledContainer>
                                                <StyledContainer mt={ifMobile ? '10px' : '10px'} fontWeight='normal' fontSize='1.2em' color='#fff'>The full version will launched on Oct 10th.</StyledContainer>
                                            </Star>
                                            <Text>Any question?</Text>
                                            <Text style={{ marginTop: '5px' }}>
                                                Contact us
                                                <a href='mailto:ido@hurricaneswap.com' target="_blank" style={{ marginLeft: '10px' }}>
                                                    <StyledContainer width='12px' height='15px' mr='5px' flex row alignItems='center' >
                                                        <Image src={mail}></Image>
                                                        <Text style={{ display: 'inline-block', marginTop: '0px', marginLeft: '5px' }}>IDO@hurricaneswap.com</Text>
                                                    </StyledContainer>
                                                </a>
                                            </Text>
                                        </>
                                    )
                                }

                                {
                                    finish && (
                                        <StyledContainer mt='33px' mb='50px'>
                                            <StyledButton mb='48px' flex alignItems='center' justifyContent='center' fontSize='28px' width={ifMobile ? '280px' : '100%'} height={ifMobile ? '80px' : '64px'}>
                                                <a style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%', color: '#fff'}} href="https://test.hurricaneswap.com/" target="_blank">To testnet</a>
                                            </StyledButton>
                                        </StyledContainer>

                                    )
                                }


                                {
                                    !ifMobile && !finish && (
                                        <>
                                            <Notice show={true} ifMobile={ifMobile} />
                                            <StyledContainer mt='35px'>
                                                {/*  */}
                                                {
                                                    account && checked && data && (
                                                        <StyledContainer mb='33px' color='#fff' flex column>
                                                            <StyledContainer flex alignItems='baseline'>
                                                                <StyledContainer fontSize='24px' flex alignItems='center'>
                                                                    Your allocation&nbsp;
                                                                    <StyledContainer flex alignItems='center' color='rgba(255, 255, 255, 0.65)'>&gt;&gt;</StyledContainer>
                                                                    &nbsp;
                                                                </StyledContainer>
                                                                <StyledContainer flex alignItems='center' fontSize='28px'>
                                                                    {data?.u} USDT.e ≈ {(data?.u / 0.03).toFixed(2)} HCT
                                                                </StyledContainer>
                                                            </StyledContainer>
                                                            <StyledContainer mt='4px' mb='4px' fontSize='12px' color='rgba(255, 255, 255, 0.65)'>
                                                                Rate: 1 HCT = 0.03 USDT.e
                                                            </StyledContainer>
                                                            <StyledContainer flex alignItems='baseline'>
                                                                <StyledContainer fontSize='24px' flex alignItems='center'>
                                                                    Your address&nbsp;
                                                                    <StyledContainer flex alignItems='center' color='rgba(255, 255, 255, 0.65)'>&gt;&gt;</StyledContainer>
                                                                    &nbsp;
                                                                </StyledContainer>
                                                                <StyledContainer flex alignItems='center' fontSize='16px'>
                                                                    {account}
                                                                </StyledContainer>
                                                            </StyledContainer>

                                                        </StyledContainer>
                                                    )
                                                }
                                                <StyledContainer pointer onClick={connectWallet}>
                                                    <StyledButton mb='48px' flex alignItems='center' justifyContent='center' fontSize='28px' width={ifMobile ? '280px' : '100%'} height={ifMobile ? '80px' : '64px'}>
                                                        {account ? (ifLoading || checkStatusLoading) ? <Loading></Loading> : (depositStatus || data?.paid) ? 'Deposit successfully' : (data?.u && data?.u > 0) ? 'Deposit USDT.e' : 'No allocation' : 'Connect Wallet'}</StyledButton>
                                                </StyledContainer>

                                                <StyledContainer wrap mt={ifMobile ? '83px' : '52px'} width='100%' flex>
                                                    <a style={{ marginRight: '80px' }} href="https://hurricaneswap.medium.com/hurricaneswap-public-sale-announcement-tutorial-daec21b5d1d1" target="_blank">
                                                        <StyledButton padding='2px' mb='48px' flex alignItems='center' justifyContent='center' fontSize='28px' width={ifMobile ? '280px' : '260px'} height={ifMobile ? '80px' : '64px'}>
                                                            <StyledContainer borderRadius='100px' width='100%' height='100%' flex alignItems='center' justifyContent='center' background='#191329'>Tutorials</StyledContainer>
                                                        </StyledButton>
                                                    </a>
                                                    <a href="https://test.hurricaneswap.com/#/" target="_blank">
                                                        <StyledButton padding='2px' flex alignItems='center' justifyContent='center' fontSize='28px' width={ifMobile ? '280px' : '260px'} height={ifMobile ? '80px' : '64px'}>
                                                            <StyledContainer borderRadius='100px' width='100%' height='100%' flex alignItems='center' justifyContent='center' background='#191329'>To Testnet</StyledContainer>
                                                        </StyledButton>
                                                    </a>

                                                </StyledContainer>
                                            </StyledContainer>
                                        </>
                                    )
                                }
                            </StyledContainer>
                        </StyledContainer>
                    </StyledContainer>
                </StyledContainer>

                {
                    ifMobile && !finish && (
                        <StyledContainer wrap mt='20px' width='100%' flex column alignItems='center' justifyContent='center'>
                            <Notice show={true} ifMobile={ifMobile} />

                            {
                                account && checked && data && (
                                    <StyledContainer pl='10px' pr='10px' mb='33px' color='#fff' flex column>
                                        <StyledContainer flex alignItems='baseline' wrap>
                                            <StyledContainer fontSize='24px' flex alignItems='center'>
                                                Your allocation&nbsp;
                                                <StyledContainer flex alignItems='center' color='rgba(255, 255, 255, 0.65)'>&gt;&gt;</StyledContainer>
                                                &nbsp;
                                            </StyledContainer>
                                            <StyledContainer flex alignItems='center' fontSize='28px'>
                                                {data?.u} USDT.e ≈ {(data?.u / 0.03).toFixed(2)} HCT
                                            </StyledContainer>
                                        </StyledContainer>
                                        <StyledContainer mt='4px' mb='4px' fontSize='12px' color='rgba(255, 255, 255, 0.65)'>
                                            Rate: 1 HCT = 0.03 USDT.e
                                        </StyledContainer>
                                        <StyledContainer flex wrap alignItems='baseline'>
                                            <StyledContainer fontSize='24px' flex alignItems='center'>
                                                Your address&nbsp;
                                                <StyledContainer flex alignItems='center' color='rgba(255, 255, 255, 0.65)'>&gt;&gt;</StyledContainer>
                                                &nbsp;
                                            </StyledContainer>
                                            <StyledContainer flex alignItems='center' fontSize='16px'>
                                                {account}
                                            </StyledContainer>
                                        </StyledContainer>

                                    </StyledContainer>
                                )
                            }

                            <StyledContainer pointer onClick={connectWallet}>
                                <StyledButton mb='48px' flex alignItems='center' justifyContent='center' fontSize='28px' width={ifMobile ? '280px' : '260px'} height={ifMobile ? '80px' : '64px'}>
                                    {account ? (ifLoading || checkStatusLoading) ? <Loading></Loading> : (depositStatus || data?.paid) ? 'Deposit successfully' : (data?.u && data?.u > 0) ? 'Deposit USDT.e' : 'No allocation' : 'Connect Wallet'}
                                </StyledButton>
                            </StyledContainer>
                            {
                                //这里放钱包点击按钮
                            }
                            <a href="https://hurricaneswap.medium.com/hurricaneswap-public-sale-announcement-tutorial-daec21b5d1d1" target="_blank">
                                <StyledButton padding='2px' mb='48px' flex alignItems='center' justifyContent='center' fontSize='28px' width={ifMobile ? '280px' : '260px'} height={ifMobile ? '80px' : '64px'}>
                                    <StyledContainer borderRadius='100px' width='100%' height='100%' flex alignItems='center' justifyContent='center' background='#191329'>Tutorials</StyledContainer>
                                </StyledButton>
                            </a>
                            <a href="https://test.hurricaneswap.com/#/" target="_blank">
                                <StyledButton padding='2px' flex alignItems='center' justifyContent='center' fontSize='28px' width={ifMobile ? '280px' : '260px'} height={ifMobile ? '80px' : '64px'}>
                                    <StyledContainer borderRadius='100px' width='100%' height='100%' flex alignItems='center' justifyContent='center' background='#191329'>To Testnet</StyledContainer>
                                </StyledButton>
                            </a>
                        </StyledContainer>

                    )
                }

            </StyledContainer>


            <Overlay dismiss={closeModal} show={ifShow} setShow={setShowModal}>
                <CheckTransactionStatus errorMsg={errorMsg} status={depositStatus}></CheckTransactionStatus>
            </Overlay>
        </>
    )
}