import { useCallback, useEffect } from 'react'
import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core'
import {
    NoEthereumProviderError,
    UserRejectedRequestError as UserRejectedRequestErrorInjected
} from '@web3-react/injected-connector'

import { setupNetwork } from '../utils'

import { baseChainID } from '../constants'
import injected from '../constants/connector'


const useAuth = () => {
    const { activate, deactivate } = useWeb3React()

    const login = useCallback((connectorID = injected, chainId = baseChainID) => {

        if (connectorID) {
            activate(connectorID, async (error) => {
                if (error instanceof UnsupportedChainIdError) {
                    console.error('Unsupported Chain Id', 'Unsupported Chain Id Error. Check your chain Id.')
                    const hasSetup = await setupNetwork(chainId)
                    if (hasSetup) {
                        activate(connectorID)
                    }


                } else if (error instanceof NoEthereumProviderError) {
                    console.error('Provider Error', 'No provider was found')
                } else if (
                    error instanceof UserRejectedRequestErrorInjected
                ) {
                    console.error('Authorization Error', 'Please authorize to access your account')
                } else {
                    console.error(error.name, error.message)
                }
            })
        } else {
            console.error('The connector config is wrong')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activate])


    const logout = useCallback(() => {
        deactivate()
        // This localStorage key is set by @web3-react/walletconnect-connector
    }, [deactivate])

    return { login, logout }
}

export default useAuth
