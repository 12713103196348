import { useMemo } from 'react'
import { useWeb3React } from "@web3-react/core"
import ERC20_ABI from '../constants/abis/erc20.json'
import { Contract } from '@ethersproject/contracts'


export function isAddress(value) {
    if (value) {
        return value
    }
    return false
    // try {
    //   // console.log('getAddress (value)', getAddress (value))
    //   return getAddress (value)
    // } catch {
    //   return false
    // }
}

export const AddressZero = "0x0000000000000000000000000000000000000000";

export function getSigner(library, account) {
    return library.getSigner(account).connectUnchecked()
}

export function getProviderOrSigner(library, account) {
    return account ? getSigner(library, account) : library
}

export function getContract(address, ABI, library, account) {
    if (!isAddress(address) || address === AddressZero) {
        throw Error(`Invalid 'address' parameter '${address}'.`)
    }
    return new Contract(address, ABI, getProviderOrSigner(library, account))
}


export function useContract(address, ABI, withSignerIfPossible = true) {
    const { library, account } = useWeb3React()

    return useMemo(() => {
        if (!address || !ABI || !library) return null
        try {
            return getContract(address, ABI, library, withSignerIfPossible && account ? account : undefined)
        } catch (error) {
            console.error('Failed to get contract', error)
            return null
        }
    }, [address, ABI, library, withSignerIfPossible, account])
}


export function useTokenContract(tokenAddress, withSignerIfPossible) {
    return useContract(tokenAddress, ERC20_ABI, withSignerIfPossible)
}