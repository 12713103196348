import styled from "styled-components";
import StyledContainer from "./Styled";

export const StyledButton = styled(StyledContainer)`
    color: #fff;
    // padding: 13px 40px;
    background: linear-gradient(87.64deg, #6600CC 0%, #3E7BFA 100%), rgba(255, 255, 255, 0.5);
    border-radius: 100px;
`
export const Image = styled.img`
    display: inline-block;
    width: 100%;
    object-fit: contain;
`

export const ImageLine = styled.img`
    display: inline-block;
    object-fit: contain;
    margin-left:5px
    
`