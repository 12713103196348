import React from 'react'
import styled from 'styled-components'
import StyledContainer from './Styled'
import mail from '../assets/images/fi_mail.svg'
import { Image } from './Preset'

const Note = styled.div`
    z-index: ${({show}) => show ? '20':'-20' };
    border-left: 2px solid #5723A6;
    padding:9px 0px 14px 14px;
`

const Text = styled.div`
    font-family: GTText;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    letter-spacing: 0.5px;
    color:#fff;
    margin-top:15px
`

const Container = styled.div`
    margin-bottom: ${({ifMobile}) => ifMobile ? '50px':'0px' };
    margin-top: ${({ifMobile}) => ifMobile ? '-60px':'0px' };
    position : ${({ifMobile}) => ifMobile ?'relative':'absolute' };
    left : ${({ifMobile}) => ifMobile ? 'null':'720px' };
    padding: ${({ifMobile}) => ifMobile ? '0 40px':'0 0px' };
    width: ${({ifMobile}) => ifMobile ? 'null':'265px' };
    top: ${({ifMobile}) => ifMobile ? 'null':'180px' };
    

`


export default ({ show , ifMobile }) => {
    return (
        <Container  ifMobile={ifMobile}>
        <Note>
            <Text>Note: </Text>
            <Text>1. You will receive HCT on Oct 10th.</Text>
            <Text>2. HurricaneSwap reserves the right to adjust the amount if necessary.</Text>
            <Text>3. If you deposit more than your allocation by accident, the excess will be refunded in 14 days.</Text>
        </Note>
        <div style={{display:'flex', flexDirection:'column',justifyContent:'flex-start' ,width:'100%'}}>
            <Text>Any question?</Text>
            <Text style={{marginTop:'5px'}}>
                Contact us  
                <a href='mailto:ido@hurricaneswap.com' target="_blank" style={{marginLeft:'10px'}}>
                    <StyledContainer width='12px' height='15px' mr='5px' flex row alignItems='center' >
                        <Image src={mail}></Image>
                    <Text style={{display:'inline-block',marginTop:'0px',marginLeft:'5px'}}>IDO@hurricaneswap.com</Text>
                    </StyledContainer>
                </a>
            </Text>
        </div>

        </Container >
    )
}
