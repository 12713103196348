import React from 'react'
import IdoHeader from '../ components/IdoHeader'
import Pie from '../ components/Pie'
import { Image } from './Preset'
import StyledContainer from './Styled'
import tele from '../assets/images/telegram.svg'
import twitter from '../assets/images/twitter.svg'
import medium from '../assets/images/medium.svg'
import mail from '../assets/images/fi_mail.svg'

export default ({ ifMobile = false }) => {
    return (
        <StyledContainer position='relative' zIndex='2' mt='23px' pt='14px' pb={ifMobile ? '20px' : '52px'} color='#AF8AE9' background='rgba(25, 19, 41, 0.5)' flex column alignItems='center'>
            <StyledContainer fontSize='12px' flex alignItems='center'>
                <StyledContainer mr='15px' width='12px' height='12px'>
                    <a href="https://t.me/HurricaneSwap" target="_blank">
                        <Image src={tele}></Image>
                    </a>
                </StyledContainer>
                <StyledContainer mr='15px' width='12px' height='12px'>
                    <a href="https://twitter.com/hurricaneswap" target="_blank">
                        <Image src={twitter}></Image>
                    </a>
                </StyledContainer>
                <StyledContainer mr='15px' width='12px' height='12px'>
                    <a href="https://hurricaneswap.medium.com" target="_blank">
                        <Image src={medium}></Image>
                    </a>
                </StyledContainer>
                <a href='mailto:contact@hurricaneswap.com' target="_blank">
                    <StyledContainer flex alignItems='center'>

                        <StyledContainer width='12px' height='12px' mr='5px'>
                            <Image src={mail}></Image>
                        </StyledContainer>
                        <StyledContainer color='#AF8AE9' >contact@hurricaneswap.com</StyledContainer>
                    </StyledContainer>
                </a>
            </StyledContainer>
            <StyledContainer mt='7px' fontSize={ifMobile ? '10px' : '16px'}>© 2021 HurricaneSwap. All rights reserved.</StyledContainer>
        </StyledContainer>
    )
}